/** Parallax */
export const layer_0 = document.getElementById('layer_0') as HTMLImageElement;
const header = document.getElementById('main-title') as HTMLImageElement;
const layer_1 = document.getElementById('layer_1') as HTMLImageElement;
// const layer_1_2 = document.getElementById('layer_1_2') as HTMLImageElement;
const layer_2 = document.getElementById('layer_2') as HTMLImageElement;
// const layer_3 = document.getElementById('layer_3')as HTMLImageElement;
// const layer_bg = document.getElementById('layer_bg')as HTMLImageElement;
const gradient_linear = document.getElementById('gradient-linear') as HTMLImageElement;
const second_bg_on_top = document.getElementById('second-bg-on-top') as HTMLImageElement;

const parallax = () => {
    const value = window.scrollY

    header!.style.top = Math.min(10 + value * .25, second_bg_on_top!.height) + 'vh'
    gradient_linear!.style.top = Math.min(value * -.25, layer_0!.height) + 'px'
    layer_0!.style.top = Math.min(value * -.25, layer_0!.height) + 'px'
    layer_1!.style.top = Math.min(value * -.1, layer_1!.height) + 'px'
    // layer_1_2!.style.top = Math.min(value * -.25, layer_1_2.height) +'px'
    layer_2!.style.top = Math.min(value * -.25, layer_2!.height) + 'px'
    // layer_3!.style.top = Math.min(value * -.25, layer_3.height) +'px'
    // layer_bg!.style.top = Math.min(value * -.25, layer_bg.height) +'px'
    // second_bg_on_top!.style.top = Math.min(90 + value * -.025, second_bg_on_top.height) +'vh'
}
window.addEventListener('scroll', parallax)