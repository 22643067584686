import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Footer: FC = () => {
  return (
    <footer>
      <div>
        <span> 🍙 </span>
        <Link to='/'>
          <p>Legal Notice</p>
        </Link>
        •<p>Portfolio</p>
        <span> 🥞 </span>
      </div>
      <p id='copyright'>copyright ©2021-2023</p>
    </footer>
  );
};
