import { FC, useEffect, useState } from 'react';

export const SlideShowProject: FC<{
  images: any[];
}> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  const handleRadioChange = (event: { target: { value: any } }) => {
    setCurrentImage(Number(event.target.value));
  };

  return (
    <section id='slide-show-project'>
      <div className='radio-buttons'>
        {images.map((_, index) => (
          <input
            key={index}
            type='radio'
            id={`img${index + 1}cb`}
            name='images'
            value={index}
            checked={currentImage === index}
            onChange={handleRadioChange}
          />
        ))}
      </div>
      <div className='wrapper-label'>
        <img
          loading='lazy'
          src={images[currentImage]}
          alt={`slide project ${currentImage + 1}`}
          min-width='512'
          height='auto'
        />
      </div>
    </section>
  );
};

export default SlideShowProject;
