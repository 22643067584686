import { FC } from 'react';

import gallery from 'images/newtspell_project/gallery.gif';
import ar_soup from 'images/soup_ar/miniature_soup.gif';
import oelifeConnect from 'images/eolife_connect/miniature.png';
import colorPicker from 'images/color_picker/color_picker.gif';
import torii from 'images/torii/torii.gif';
import gingerBread from 'images/ginger_bread/gingerBread.gif';
import infiniteRoad from 'images/infinite_road/infinite_road.gif';

import { CardProject } from '../commons/CardProject';

export const Experience: FC = () => {
  return (
    <section id='experience'>
      <div className='title-section'>
        <div>
          <h2 className='title' data-text='EXPERIENCE'>
            EXPERIENCE
          </h2>
        </div>
      </div>
      <div className='subTitle-section'>
        <h3>Professional work</h3>
      </div>
      <div id='boxes-experience'>
        <CardProject image={gallery} id={0} />
        <CardProject image={oelifeConnect} id={1} />
        <CardProject image={ar_soup} id={2} />
      </div>
      <div className='subTitle-section'>
        <h3>Labs</h3>
      </div>
      <div id='boxes-experience'>
        <CardProject image={colorPicker} id={3} noPageDetails={true} />
        <CardProject image={torii} id={4} noPageDetails={true} />
        <CardProject image={gingerBread} id={5} noPageDetails={true} />
        <CardProject image={infiniteRoad} id={6} noPageDetails={true} />
      </div>
    </section>
  );
};
