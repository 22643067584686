import skills from '../skills.json'

/** Carousel */
const content_carousel = document.querySelector('.content-carousel')
const index_skills = document.querySelector('#index-skills')
const length_skills = document.querySelector('#length-skills')
const middleCountLabels = 20

const arraySkills: { name: any, value: any }[] = []
const createArraySkills = () => {
    for (let index = 0; index < skills.children.length; index++) {
        for (let skill = 0; skill < skills.children[index].children.length; skill++) {
            arraySkills.push({ name: skills.children[index].children[skill].name, value: skills.children[index].children[skill].value })
        }
    }
}
createArraySkills()

let currentSkillChecked = middleCountLabels

const displayCountSkills = (data: number) => {
    currentSkillChecked = data
    index_skills!.textContent = currentSkillChecked.toString();
}
displayCountSkills(currentSkillChecked)

const checkMyLevel = (data: any) => {
    let levelOfCurrent
    if (data > 0 && data <= 15) levelOfCurrent = 'foundation'
    if (data > 15 && data <= 40) levelOfCurrent = 'intermediate'
    if (data > 40 && data <= 100) levelOfCurrent = 'advanced'
    return levelOfCurrent
}

const arrayInputs: any[] = []
const addInputs = () => {

    for (let index = 0; index < arraySkills.length; index++) {
        const input = document.createElement('input')
        arrayInputs.push(input)
    }

    const toAdd = document.createDocumentFragment()
    for (let index = 0; index < arrayInputs.length; index++) {
        let id_input = 's' + index

        arrayInputs[index].setAttribute('id', id_input)
        arrayInputs[index].setAttribute('class', id_input)
        arrayInputs[index].setAttribute('name', 'slider')
        arrayInputs[index].setAttribute('type', 'radio')
        if (index === middleCountLabels)
            arrayInputs[index].checked = true;
        else
            arrayInputs[index].checked = false;
        toAdd.appendChild(arrayInputs[index])
    }

    content_carousel!.appendChild(toAdd)
}

const addLabels = () => {
    const toAdd = document.createDocumentFragment()

    const arrayLabelsBG = []
    for (let index = 0; index < arraySkills.length; index++) {
        const labelBG = document.createElement('label')
        arrayLabelsBG.push(labelBG)
    }
    for (let index = 0; index < arrayLabelsBG.length; index++) {
        let id_input = 's' + index
        let class_label = 'slide' + index

        arrayLabelsBG[index].innerHTML = `<p>${arraySkills[index].name}</p>`
        arrayLabelsBG[index].setAttribute('for', id_input)
        arrayLabelsBG[index].setAttribute('class', class_label)
        arrayLabelsBG[index].classList.add('bg')
        toAdd.appendChild(arrayLabelsBG[index])
    }
    content_carousel!.appendChild(toAdd)

    const arrayLabels = []
    for (let index = 0; index < arraySkills.length; index++) {
        const label = document.createElement('label')
        arrayLabels.push(label)
    }
    for (let index = 0; index < arrayLabels.length; index++) {
        let id_input = 's' + index
        let class_label = 'slide' + index
        let levelOfCurrent = checkMyLevel(arraySkills[index].value)

        arrayLabels[index].innerHTML = `<p>${levelOfCurrent}</p>`
        arrayLabels[index].setAttribute('for', id_input)
        arrayLabels[index].setAttribute('class', class_label)
        arrayLabels[index].classList.add('data')
        if (levelOfCurrent !== undefined) {
            arrayLabels[index].classList.add(levelOfCurrent);
        } toAdd.appendChild(arrayLabels[index])
    }
    content_carousel!.appendChild(toAdd)
}

const initCarousel = () => {
    length_skills!.textContent = arraySkills.length.toString()
    addInputs()
    addLabels()
}
initCarousel()


// arrows and count skills
const updateCountSkillsWithArrows = (directionCarousel: string) => {
    const skillChecked = document.querySelector(`#s${currentSkillChecked}`) as HTMLInputElement;
    if (directionCarousel === 'left' && currentSkillChecked >= 1) {
        --currentSkillChecked
        currentSkillChecked === 0 ? displayCountSkills(currentSkillChecked + 1) : displayCountSkills(currentSkillChecked)
        skillChecked.checked = true
    } else if (directionCarousel === 'right' && currentSkillChecked < arraySkills.length) {
        ++currentSkillChecked
        displayCountSkills(currentSkillChecked)
        skillChecked.checked = true
    }
}

arrayInputs.forEach(input => {
    input.addEventListener('change', () => {
        displayCountSkills(input.className.slice(1, 3))
    })
})

const changeCheckedSkill = () => {
    const buttonLeftArrow = document.getElementById('leftArrow')
    const buttonRightArrow = document.getElementById('rightArrow')

    buttonLeftArrow!.addEventListener('click', () => {
        updateCountSkillsWithArrows('left')
    })

    buttonRightArrow!.addEventListener('click', () => {
        updateCountSkillsWithArrows('right')
    })
}
changeCheckedSkill()