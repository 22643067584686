import React, { FC, useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface Node {
  name: string;
  children?: Node[];
  value?: number;
  current?: any;
}

const data: Node = {
  name: 'Skills',
  children: [
    {
      name: 'Hobbies',
      children: [
        { name: 'NFT creating', value: 30 },
        { name: 'Crypto trading', value: 35 },
        { name: 'Gaming', value: 20 },
        { name: 'Manga', value: 15 },
        { name: 'Travelling', value: 40 },
      ],
    },
    {
      name: 'Strong points',
      children: [
        { name: 'Work autonomously', value: 30 },
        { name: 'Team spirit', value: 30 },
        { name: 'My curiosity', value: 40 },
        { name: 'Ready to remote work', value: 40 },
      ],
    },
    {
      name: 'Methods',
      children: [
        { name: 'Scrum', value: 50 },
        { name: 'Clean code', value: 25 },
        { name: 'BDD', value: 12.5 },
        { name: 'TDD', value: 12.5 },
      ],
    },
    {
      name: 'Stack',
      children: [
        { name: 'Javascript', value: 24 },
        { name: 'Typescript', value: 15 },
        { name: 'HTML', value: 12 },
        { name: 'CSS/SASS', value: 12 },
        { name: 'ReactJS', value: 9 },
        { name: 'Redux', value: 7 },
        { name: 'React Native', value: 5 },
        { name: 'Victory Native', value: 7 },
        { name: 'D3JS', value: 3 },
        { name: 'Phaser', value: 5 },
        { name: 'GSAP', value: 7 },
        { name: 'ThreeJS', value: 9 },
        { name: 'BabylonJS', value: 9 },
        { name: 'NodeJS', value: 9 },
        { name: 'Socket.io', value: 7 },
        { name: 'Web RTC', value: 4 },
        { name: 'API REST', value: 9 },
        { name: 'PHP', value: 9 },
        { name: 'Symfony', value: 4 },
        { name: 'SQL', value: 4 },
        { name: 'Git', value: 7 },
        { name: 'Jest', value: 7 },
        { name: 'Cucumber', value: 7 },
        { name: 'Webpack', value: 7 },
        { name: 'Unity 3D', value: 5 },
        { name: 'Blender', value: 11 },
        { name: 'Illustrator', value: 9 },
        { name: 'Photoshop', value: 9 },
        { name: 'Figma', value: 9 },
      ],
    },
  ],
};

const PartitionChart: FC = () => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const width = 340;
    const height = 340;
    const radius = width / 6;

    const partition = (data: any) => {
      const root = d3
        .hierarchy(data)
        .sum((d) => d.value || 0)
        .sort((a, b) => (b.value || 0) - (a.value || 0));
      return d3.partition().size([2 * Math.PI, root.height + 1])(root);
    };

    const format = d3.format(',d');

    const root = partition(data);
    root.each((d: any) => (d.current = d));

    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', [0, 0, width, height])
      .attr('preserveAspectRatio', 'xMinYMin')
      .style('font', '20px sans-serif');

    const graph = svg
      .append('g')
      .attr('transform', `translate(${width / 2},${height / 2})`);

    const arc = d3
      .arc()
      .startAngle((d: any) => d.x0)
      .endAngle((d: any) => d.x1)
      .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d: any) => d.y0 * radius)
      .outerRadius((d: any) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    const color = d3.scaleOrdinal(
      d3.quantize(d3.interpolateRainbow, data.children!.length + 1)
    );

    const path = graph
      .append('g')
      .selectAll('path')
      .data(root.descendants().slice(1))
      .join('path')
      .attr('fill', (d: any) => {
        while (d.depth > 1) d = d.parent;
        return color(d.data.name);
      })
      .attr('fill-opacity', (d: any) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
      )
      .attr('d', (d: any) => arc(d.current));

    // .on('click', clicked)
    path.style('cursor', 'pointer').attr('pointer-event', 'visibleStroke');

    path.append('title').text(
      (d) =>
        `${d
          .ancestors()
          .map((d: any) => d.data.name)
          .reverse()
          .join('/')}\n${format(d.value || 0)}`
    );

    const label = graph
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(root.descendants().slice(1))
      .join('text')
      .attr('dy', '0.35em')
      .style('font-size', '5px')
      .attr('fill-opacity', (d: any) => +labelVisible(d.current))
      .attr('transform', (d: any) => labelTransform(d.current))
      .text((d: any) => d.data.name);

    const parent = graph
      .append('circle')
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .style('cursor', 'pointer');
    // .on('click', clicked);

    // function clicked(event: any, p: any) {
    //   parent.datum(p.parent || root);
    //   root.each((d: any) => {
    //     d.target = {
    //       x0:
    //         Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
    //         2 *
    //         Math.PI,
    //       x1:
    //         Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
    //         2 *
    //         Math.PI,
    //       y0: Math.max(0, d.y0 - p.depth),
    //       y1: Math.max(0, d.y1 - p.depth),
    //     };
    //   });

    //   const t = graph.transition().duration(750);

    //   path
    //     .transition()
    //     .tween('data', (d: any) => {
    //       const i = d3.interpolate(d.current, d.target);
    //       return (t: any) => {
    //         d.current = i(t);
    //         return () => arc(d.current);
    //       };
    //     })
    //     .filter(function (d: any) {
    //       if (this instanceof Element) {
    //         return !!this.getAttribute('fill-opacity') || arcVisible(d.target);
    //       }
    //       return false;
    //     })
    //     .attr('fill-opacity', (d: any) =>
    //       arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
    //     )
    //     .attrTween('d', null);

    //   label
    //     .filter(function (d: any) {
    //       if (this instanceof Element) {
    //         return (
    //           !!this.getAttribute('fill-opacity') || labelVisible(d.target)
    //         );
    //       }
    //       return false;
    //     })
    //     .transition()
    //     .attr('fill-opacity', (d: any) => +labelVisible(d.target))
    //     .attrTween('transform', (d: any) => () => labelTransform(d.current));
    // }

    function arcVisible(d: any) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d: any) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d: any) {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }, [data]);

  return <svg ref={svgRef}></svg>;
};

export default PartitionChart;
