/** Scroll reveal */
export const sr = ScrollReveal({
    distance: '60px',
    duration: 2500,
    delay: 400
})
sr.reveal(`.box-experience`, { origin: 'left', interval: 100 })
sr.reveal(`.box-expertise`, { origin: 'right', interval: 100 })
ScrollReveal().reveal(`#icon-hand-first`, { origin: 'top', delay: 50, distance: '10px' })
ScrollReveal().reveal(`#icon-hand-second`, { origin: 'bottom', delay: 50, distance: '10px' })
ScrollReveal().reveal('#sub-title-contact span', { distance: '0px', scale: 0, delay: 0 })
ScrollReveal().reveal(`#datas-skills, #wrapper-carousel`, { scale: 0.75, distance: '0px', delay: 600 })


/**Scroll sections active link */
const sections = document.querySelectorAll('section[id]')

const scrollActive = () => {
    const scrollY = window.pageYOffset
    sections.forEach(current => {
        const section = current as HTMLElement;
        const sectionHeight = section.offsetHeight
        const sectionTop = section.offsetTop + 250
        const sectionId = section.getAttribute('id')

        const navLink = document.querySelector('nav a[href*=' + sectionId + ']');

        if (navLink) {

            if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
                navLink.classList.add('active-link')
            } else {
                navLink.classList.remove('active-link')
            }
        }
    })
}
window.addEventListener('scroll', scrollActive)