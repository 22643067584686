import { useState } from 'react';
import {
  Experience,
  Expertise,
  Footer,
  Header,
  Presentation,
  InfosContact,
  Parallax,
} from '../components';

import '../scss/style.scss';

export const Layout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleOutsideClick = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div id='wrapper'>
      <div
        onClick={handleOutsideClick}
        id='menu-burger'
        className={`burger ${isSidebarOpen === true ? 'is-toggled' : ''}`}
      >
        <span id='span-menu-burger'></span>
      </div>
      <div
        className={`sidebar ${isSidebarOpen === true ? 'is-collapsed' : ''}`}
        id='wrapper-sidebar'
      >
        <div id='content-sidebar'>
          <Header />
          <InfosContact />
        </div>
      </div>
      <div id='container'>
        <div id='second-bg-on-top' />
        <Parallax />
        <div id='bg-presentation' />
        <main id='wrapper-content'>
          <Presentation />
          <Expertise />
          <Experience />
        </main>
        <div id='container-footer-layout'>
          <Footer />
        </div>
      </div>
    </div>
  );
};
