import { FC } from 'react';
import { Link } from 'react-router-dom';

import { FaLinkedin, FaGitlab } from 'react-icons/fa';

export const InfosContact: FC = () => {
  return (
    <div id='infos-contact'>
      <Link to={'https://www.linkedin.com/in/iza-dev/'}>
        <FaLinkedin className='social-icon' />
      </Link>
      <Link to={'https://gitlab.com/iza-dev'}>
        <FaGitlab className='social-icon' />
      </Link>
      <div id='content'>
        <Link to='mailto:contact@izadev.tech' id='mail'>
          contact@izadev.tech
        </Link>
      </div>
    </div>
  );
};
