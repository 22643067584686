import { useState, useEffect, FC } from 'react';

import skills from '../../skills.json';

export const Carousel: FC = () => {
  const middleCountLabels = 21;
  const arraySkills: any = [];

  const [currentSkillChecked, setCurrentSkillChecked] =
    useState(middleCountLabels);

  const updateCountSkillsWithArrows = (directionCarousel: any) => {
    if (directionCarousel === 'left' && currentSkillChecked >= 1) {
      setCurrentSkillChecked(currentSkillChecked - 1);
    } else if (
      directionCarousel === 'right' &&
      currentSkillChecked < arraySkills.length
    ) {
      setCurrentSkillChecked(currentSkillChecked + 1);
    }
  };

  useEffect(() => {
    const content_carousel = document.querySelector('.content-carousel');
    const index_skills = document.querySelector('#index-skills');
    const length_skills = document.querySelector('#length-skills');

    const createArraySkills = () => {
      for (let index = 0; index < skills.children.length; index++) {
        for (
          let skill = 0;
          skill < skills.children[index].children.length;
          skill++
        ) {
          arraySkills.push({
            name: skills.children[index].children[skill].name,
            value: skills.children[index].children[skill].value,
          });
        }
      }
    };
    createArraySkills();

    const displayCountSkills = (data: any) => {
      setCurrentSkillChecked(data);
      if (index_skills)
        index_skills.textContent = currentSkillChecked.toString();
    };
    displayCountSkills(currentSkillChecked);

    const checkMyLevel = (data: any) => {
      let levelOfCurrent;
      if (data > 0 && data <= 15) levelOfCurrent = 'foundation';
      if (data > 15 && data <= 40) levelOfCurrent = 'intermediate';
      if (data > 40 && data <= 100) levelOfCurrent = 'advanced';
      return levelOfCurrent;
    };

    const arrayInputs: any[] = [];
    const addInputs = () => {
      for (let index = 0; index < arraySkills.length; index++) {
        const input = document.createElement('input');
        arrayInputs.push(input);
      }

      const toAdd = document.createDocumentFragment();
      for (let index = 0; index < arrayInputs.length; index++) {
        let id_input = 's' + index;

        arrayInputs[index].setAttribute('id', id_input);
        arrayInputs[index].setAttribute('class', id_input);
        arrayInputs[index].setAttribute('name', 'slider');
        arrayInputs[index].setAttribute('type', 'radio');
        if (index === middleCountLabels) arrayInputs[index].checked = true;
        toAdd.appendChild(arrayInputs[index]);
      }

      content_carousel!.appendChild(toAdd);
    };

    const addLabels = () => {
      const toAdd = document.createDocumentFragment();

      const arrayLabelsBG = [];
      for (let index = 0; index < arraySkills.length; index++) {
        const labelBG = document.createElement('label');
        arrayLabelsBG.push(labelBG);
      }
      for (let index = 0; index < arrayLabelsBG.length; index++) {
        let id_input = 's' + index;
        let class_label = 'slide' + index;

        arrayLabelsBG[index].innerHTML = `<p>${arraySkills[index].name}</p>`;
        arrayLabelsBG[index].setAttribute('for', id_input);
        arrayLabelsBG[index].setAttribute('class', class_label);
        arrayLabelsBG[index].classList.add('bg');
        toAdd.appendChild(arrayLabelsBG[index]);
      }
      content_carousel!.appendChild(toAdd);

      const arrayLabels = [];
      for (let index = 0; index < arraySkills.length; index++) {
        const label = document.createElement('label');
        arrayLabels.push(label);
      }
      for (let index = 0; index < arrayLabels.length; index++) {
        let id_input = 's' + index;
        let class_label = 'slide' + index;
        let levelOfCurrent = checkMyLevel(arraySkills[index].value);

        arrayLabels[index].innerHTML = `<p>${levelOfCurrent}</p>`;
        arrayLabels[index].setAttribute('for', id_input);
        arrayLabels[index].setAttribute('class', class_label);
        arrayLabels[index].classList.add('data');
        if (levelOfCurrent) arrayLabels[index].classList.add(levelOfCurrent);
        toAdd.appendChild(arrayLabels[index]);
      }
      content_carousel!.appendChild(toAdd);
    };

    const initCarousel = () => {
      if (length_skills) length_skills.textContent = arraySkills.length;
      addInputs();
      addLabels();
    };
    initCarousel();

    const changeCheckedSkill = () => {
      const buttonLeftArrow = document.getElementById('leftArrow');
      const buttonRightArrow = document.getElementById('rightArrow');

      buttonLeftArrow!.addEventListener('click', () => {
        console.log('left');
        updateCountSkillsWithArrows('left');
      });

      buttonRightArrow!.addEventListener('click', () => {
        console.log('right');
        updateCountSkillsWithArrows('right');
      });
    };
    changeCheckedSkill();
  }, [currentSkillChecked]);

  return (
    <div id='wrapper-carousel'>
      <div className='content-carousel'></div>
    </div>
  );
};

export default Carousel;
