import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import projects from '../../projects.json';

export const CardProject: FC<{
  image: any;
  id: number;
  noPageDetails?: boolean;
}> = ({ image, id, noPageDetails }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  return (
    <div
      className={`box-experience ${isActive ? 'is-active' : ''}`}
      onClick={toggleActive}
    >
      <div className='imgbox'>
        <img
          loading='lazy'
          src={image}
          alt='miniature for project'    
        />
        <Link
          to={
            noPageDetails
              ? projects.projects[id].project_information.project_url
              : `/project-details/${id}`
          }
        >
          <div className='btn-project-details'>
            <p>click me</p>
          </div>
        </Link>
        <div className='title-project'>
          <p>{projects.projects[id].title}</p>
        </div>
      </div>
      <div className='tagsbox'>
        {projects.projects[id].stacks.slice(0, 3).map((stack, index) => (
          <div className='tag' key={`${stack}-${index}`}>
            <span>{stack}</span>
          </div>
        ))}
      </div>
      <div className='content-card-experience'>
        <h3> {projects.projects[id].title}</h3>
        <p>{projects.projects[id].resume}</p>
      </div>
    </div>
  );
};
