import { createBrowserRouter } from 'react-router-dom';

import { Project, Page404, Layout } from './pages';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Page404 />,
  },
  {
    path: 'project-details/:id',
    element: <Project />,
  },
]);
