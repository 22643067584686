import { FC } from 'react';
import { Link } from 'react-router-dom';

export const Page404: FC = () => {
  return (
    <section className='page-error'>
      <div id='wrapper'>
        <div className='container-title'>
          <div>
            <h1 className='title' data-text='Oops!'>
              Oops!
            </h1>
          </div>
        </div>
        <div className='content-page-error'>
          <h2>404 - PAGE NOT FOUND</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporatily unavailable
          </p>
        </div>
        <div className='btn-home'>
          <Link to='/'>
            <button aria-label='Back to home page'>GO TO HOMEPAGE</button>
          </Link>
        </div>
      </div>
    </section>
  );
};
