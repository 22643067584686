import { FC } from 'react';

import { FaHandSpock } from 'react-icons/fa';

export const Presentation: FC = () => {
  return (
    <section id='presentation'>
      <div id='content-presentation'>
        <FaHandSpock
          className='spock-icon'
          id='icon-hand-first'
          color='#7F8A79'
        />
        <blockquote>
          <p>
            Hi,
            <br /> I'm a frontend developer who values autonomy and aspires to
            excel in crafting high-quality products. My primary goal is to
            deliver an outstanding user experience through clean code and
            thoughtful user interface design.
          </p>
        </blockquote>
        <FaHandSpock
          className='spock-icon'
          id='icon-hand-second'
          color='#7F8A79'
        />
        <div className='border-presentation' id='cube-1' />
        <div className='border-presentation' id='cube-2' />
      </div>
    </section>
  );
};
