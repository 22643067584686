/** Contact */
export const inputs = document.querySelectorAll('input')
const textarea = document.querySelector('textarea')
inputs.forEach(input => {
    input.addEventListener('change', () => {
        const label = document.querySelector(`label[for=${input.id}]`) as HTMLElement
        if (input.value.length === 0) {
            label!.style.top = '10px'
            label!.style.fontSize = '1rem'
        }
        if (input.value.length > 0) {
            label!.style.top = '-5px'
            label!.style.fontSize = '.75rem'
        }

    })
})
textarea!.addEventListener('change', () => {
    const label = document.querySelector(`label[for=${textarea!.id}]`) as HTMLElement
    if (textarea!.value.length === 0) {
        label!.style.top = '10px'
        label!.style.fontSize = '1rem'
    }
    if (textarea!.value.length > 0) {
        label!.style.top = '-5px'
        label!.style.fontSize = '.75rem'
    }
})