import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Footer, SlideShowProject } from 'components';
import project from '../projects.json';

import { loadImage } from 'utils/loadImage';

// import bigPicture from 'images/newtspell_project/big_picture.png';
// import image1 from 'images/newtspell_project/newtspell_1.png';
// import image2 from 'images/newtspell_project/newtspell_2.png';
// import image3 from 'images/newtspell_project/newtspell_3.png';

export const Project = () => {
  const { id } = useParams();

  const projectCurrent = project.projects.find(
    (project) => project.id === parseInt(id!)
  );

  const bigPicture = loadImage(
    projectCurrent?.images.path_big_picture as string
  );
  const image1 = loadImage(projectCurrent?.images.path_1 as string);
  const image2 = loadImage(projectCurrent?.images.path_2 as string);
  const image3 = loadImage(projectCurrent?.images.path_3 as string);
  const image4 = loadImage(projectCurrent?.images.path_4 as string);
  const image5 = loadImage(projectCurrent?.images.path_5 as string);
  const image6 = loadImage(projectCurrent?.images.path_6 as string);
  const image7 = loadImage(projectCurrent?.images.path_7 as string);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    <div id='container-project'>
      <div id='container-big-picture'>
        <img
          id='big-picture-project'
          src={bigPicture}
          alt={projectCurrent?.images.path_big_picture}
        />
        <a href='#header-project'>
          <div id='scroll-down'></div>
        </a>
        <header id='header-project'>
          <div>
            <h1>{projectCurrent?.title}</h1>
            <a href='/#experience'>
              <i id='icon-return' className='fas fa-reply'></i>
            </a>
            <ol>
              <li>
                <a href='/'>Home</a>
              </li>
              <li>
                <a href='/#experience'>
                  <span> 🔥 </span> Other projects <span> 🔥 </span>
                </a>
              </li>
            </ol>
          </div>
        </header>
      </div>
      <main id='content-project-details'>
        <section id='infos-resume'>
          <div id='sub-title-project'>
            <h2>{projectCurrent?.subTitle}</h2>
          </div>
          <div id='infos-project'>
            <div id='container-infos-project'>
              <div id='title-infos-project'>
                <h3>Project information</h3>
              </div>
              <ul>
                <li>
                  <strong>Category</strong>:
                   {` ${projectCurrent?.project_information.category}`}
                </li>
                <li>
                  <strong>Client</strong>:
                      {` ${projectCurrent?.project_information.client}`}
                </li>
                <li>
                  <strong>Project date</strong>:
                      {` ${projectCurrent?.project_information.project_date}`}
                </li>
                <li>
                  <strong>Project URL</strong>:
                  <a href={projectCurrent?.project_information.project_url}>
                        {` ${projectCurrent?.project_information.project_url}`}
                  </a>
                </li>
                <li>
                  <strong>Repo</strong>:
                  <a href={projectCurrent?.project_information.project_gitlab}>
                    {' '}Gitlab
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section id='text-presentation-project'>
          <p>{projectCurrent?.text}</p>
          <h4>Stack :</h4>
          <ul>
            {projectCurrent?.stacks.map((stack, index) => (
              <li key={`${stack}-${index}`}>{stack}</li>
            ))}
          </ul>
        </section>
        <SlideShowProject images={[image1, image2, image3, image4, image5, image6, image7]} />
      </main>
      <div id='container-footer-project'>
        <Footer />
      </div>
    </div>
    </>
  );
};
