import { motion, useAnimation } from 'framer-motion';
import { FC, useEffect } from 'react';

import layer_0 from '../../images/2750/avatar_jungle_Layer_0_2750.png';
import layer_1 from '../../images/2750/avatar_jungle_Layer_1_2750.png';
import layer_bg from '../../images/2750/avatar_jungle_Layer_2_2750.png';

export const Parallax: FC = () => {
  const controlLayer0 = useAnimation();
  const controlLayer1 = useAnimation();
  const controlLayerBG = useAnimation();

  useEffect(() => {
    const updateScroll = () => {
      const scrollY = window.scrollY;
      controlLayer0.start({ y: -scrollY * 0.25 });
      controlLayer1.start({ y: -scrollY * 1 });
      controlLayerBG.start({ y: -scrollY * 0.5 });
    };

    window.addEventListener('scroll', updateScroll);

    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  }, [controlLayer0, controlLayer1, controlLayerBG]);

  return (
    <div id='img-avatarInJungle'>
      <h1 id='main-title'>portfolio</h1>
      <motion.img
        initial={{ y: 0 }}
        animate={controlLayer1}
        src={layer_1}
        alt='First image of avatar'
        id='layer_1'
        className='img-avatar'
      />
      <motion.img
        initial={{ y: 0 }}
        animate={controlLayer0}
        src={layer_0}
        alt='Second image of avatar'
        id='layer_2'
        className='img-avatar'
      />
      <motion.img
        initial={{ y: 0 }}
        animate={controlLayerBG}
        src={layer_bg}
        alt='Third image of avatar'
        id='layer_bg'
        className='img-avatar'
      />
      <motion.div id='gradient-linear' animate={controlLayer0} />
    </div>
  );
};
